$(document).on('turbolinks:load', function () {
  // 配達指定日時ピッカーの設定
  var order_timepicker_from = $('#order_datetimepicker');
  var order_datetime_input = $('#order_order_datetime');
  if (order_timepicker_from.length) {
    // data属性から値を取得
    var daysLater = parseInt(order_timepicker_from.data('days-later')) || 0; // デフォルト0日後
    var dayslimit = parseInt(order_timepicker_from.data('days-limit')) || 60; // デフォルト60日後
    var startTimeValue = order_timepicker_from.data('start-time') || '0000'; // デフォルト00:00
    var endTimeValue = order_timepicker_from.data('end-time') || '2359'; // デフォルト23:59

    // 4桁の時刻を時間と分に分割する関数
    function parseTime(timeStr) {
      var timeInt = parseInt(timeStr, 10);
      var hours = Math.floor(timeInt / 100);
      var minutes = timeInt % 100;
      return { hours: hours, minutes: minutes };
    }

    var startTime = parseTime(startTimeValue);
    var endTime = parseTime(endTimeValue);

    // 開始可能日時と終了可能日時を設定
    var minDateFrom = moment().add(daysLater, 'days').startOf('day').hour(startTime.hours).minute(startTime.minutes);
    var maxDateFrom = moment().add(dayslimit, 'days').endOf('day').hour(endTime.hours).minute(endTime.minutes);

    var datetimepickerOptionsFrom = {
      format: 'YYYY/MM/DD HH:mm',
      stepping: 15, // 15分刻み
      locale: 'ja',
      useCurrent: false,
      minDate: minDateFrom,
      maxDate: maxDateFrom,
      defaultDate: null,
      // 選択可能な時間帯を設定
      enabledHours: Array.from(
        { length: endTime.hours - startTime.hours + 1 },
        (_, i) => startTime.hours + i
      ),
      // 分単位の制限
      disabledTimeIntervals: []
    };

    // 分単位の制限を設定
    if (startTime.minutes > 0) {
      // 開始時刻の分未満を無効化
      datetimepickerOptionsFrom.disabledTimeIntervals.push([
        moment({ hour: startTime.hours, minute: 0 }),
        moment({ hour: startTime.hours, minute: startTime.minutes - 1 })
      ]);
    }
    if (endTime.minutes < 59) {
      // 終了時刻の分超過を無効化
      datetimepickerOptionsFrom.disabledTimeIntervals.push([
        moment({ hour: endTime.hours, minute: endTime.minutes + 1 }),
        moment({ hour: endTime.hours, minute: 59 })
      ]);
    }
    // 開始時刻以前と終了時刻以降の時間帯を無効化
    datetimepickerOptionsFrom.disabledTimeIntervals.push(
      [moment({ hour: 0, minute: 0 }), moment({ hour: startTime.hours - 1, minute: 59 })],
      [moment({ hour: endTime.hours + 1, minute: 0 }), moment({ hour: 23, minute: 59 })]
    );

    order_timepicker_from.datetimepicker(datetimepickerOptionsFrom);
  }

  // 終了日時ピッカーの設定（必要に応じて設定）
  var order_timepicker_to = $('#order_datetimepicker_end');
  var order_datetime_input_end = $('#order_order_datetime_end');
  if (order_timepicker_to.length) {
    // 入力フィールドの既存の値を取得
    var currentDateToValue = order_datetime_input_end.val();
    var defaultDateTo = currentDateToValue ? moment(currentDateToValue, 'YYYY-MM-DD HH:mm:ss Z') : null;

    var datetimepickerOptionsTo = $.extend(true, {}, datetimepickerOptionsFrom);
    datetimepickerOptionsTo.defaultDate = defaultDateTo;

    order_timepicker_to.datetimepicker(datetimepickerOptionsTo);
  }

  // 開始日時変更時に終了日時ピッカーを更新
  if (order_timepicker_from.length && order_timepicker_to.length) {
    order_timepicker_from.on("change.datetimepicker", function (e) {
      var selectedDate = e.date;

      // 以前の制限をリセット
      order_timepicker_to.datetimepicker('minDate', false);
      order_timepicker_to.datetimepicker('maxDate', false);

      // 新しい制限を設定
      var newMinDate = moment(selectedDate).add(30, 'm');
      var newMaxDate = moment(selectedDate).add(180, 'm');

      // 選択可能な時間帯に制限
      var earliestTime = moment(selectedDate).startOf('day').hour(startTime.hours).minute(startTime.minutes);
      var latestTime = moment(selectedDate).startOf('day').hour(endTime.hours).minute(endTime.minutes);

      if (newMinDate.isBefore(earliestTime)) {
        newMinDate = earliestTime;
      }
      if (newMaxDate.isAfter(latestTime)) {
        newMaxDate = latestTime;
      }

      order_timepicker_to.datetimepicker('minDate', newMinDate);
      order_timepicker_to.datetimepicker('maxDate', newMaxDate);

      // 既存の終了日時が新しい範囲外の場合のみデフォルト値を設定
      var currentToDate = order_timepicker_to.datetimepicker('date');
      if (!currentToDate || currentToDate.isBefore(newMinDate) || currentToDate.isAfter(newMaxDate)) {
        order_timepicker_to.datetimepicker('date', newMinDate);
      }
    });
  }

  $('input[name="order[order_datetime_asort]"]:radio').change(function () {
    if ($(this).val() == 'time_order') {
      $('#order_order_datetime').removeAttr('disabled');
      $('#order_order_datetime_end').removeAttr('disabled');
    } else {
      $('#order_order_datetime').attr('disabled', 'disabled');
      $('#order_order_datetime_end').attr('disabled', 'disabled');
    }
  });

  $('input[name="order[receipt_flag]"]:radio').change(function () {
    if ($(this).val() == 'receipt_flag_on') {
      $('#order_receipt_address').removeAttr('disabled');
    } else {
      $('#order_receipt_address').attr('disabled', 'disabled');
    }
  });

  receipt_flag_state();

  $('input[name="order[pay_asort]"]:radio').change(function () {
    receipt_flag_state();
  });

  coupon_view_state();

  $('#btn_modal_ok').click(function () {
    check_coupon($('#input_coupon_code').val());
    return false;
  });

  $('#add_coupon_code').click(function () {
    coupon_view_state();
    $('#coupon_code_modal').modal({ 'backdrop': 'static' });
  });

  $('#clear_coupon_btn').click(function () {
    $('#order_code').val("");
    coupon_view_state();
    return false;
  });


});

var coupon_view_state = function () {
  var order_code_elm = $('#order_code');
  if (order_code_elm.get(0)) {
    var coupon_code = order_code_elm.val();
    if (coupon_code !== null && coupon_code.length > 0) {
      $('#add_coupon_code').hide();
      $('#added_coupon_area').show();
    } else {
      $('#add_coupon_code').show();
      $('#added_coupon_area').hide();
      $('#added_coupon_code').text("");
    }
  }
  $('#coupon_code_input').removeClass("field_with_errors");
  $('#coupon_code_error').text("");
  $('.dialog-has-error').hide();
};

var receipt_flag_state = function () {
  var pay_asort_elm = $('input[name="order[pay_asort]"]:checked');
  if (pay_asort_elm.get(0)) {
    var pay_asort = pay_asort_elm.val();
    if (pay_asort == 'after_invoicing') {
      $('input[name="order[receipt_flag]"]').val(["receipt_flag_off"]);
      $('input[name="order[receipt_flag]"]').attr('disabled', 'disabled');
      $('#order_receipt_address').attr('disabled', 'disabled');
      $('#buyer_info').fadeIn("slow");
    } else {
      $('input[name="order[receipt_flag]"]').removeAttr('disabled');
      $('#order_receipt_address').removeAttr('disabled');
      $('#buyer_info').fadeOut("slow");
    }
  }
};

window.coupon_view_state = coupon_view_state;

var check_coupon = function (coupon) {
  $.ajax({
    url: "/order_sessions/check_coupon",
    type: "POST",
    data: { code: coupon },
    dataType: "script"

  }).done(function (data) {

  }).fail(function (data) {

  });
};